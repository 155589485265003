<template>
    <div>
        <div class="page-content-box">
            <div class="page-filter-box">
                <el-row :gutter="16">
                    <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4">
                        <el-input v-model="params.searchValue" class="input-with-select" size="small">
                            <el-select v-model="params.searchType" slot="prepend" placeholder="请选择">
                                <el-option label="商品ID" :value="1"></el-option>
                                <el-option label="商品名称" :value="2"></el-option>
                                <el-option label="skuId" :value="3"></el-option>
                                <el-option label="sku名称" :value="4"></el-option>
                                <el-option label="运单号" :value="5"></el-option>
                                <el-option label="订单编号" :value="6"></el-option>
                            </el-select>
                        </el-input>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4">
                        <el-select v-model="params.expressId" placeholder="请选择快递公司" size="small">
                            <el-option value="" label="不限制快递公司"></el-option>
                            <el-option v-for="(item,index) in expressList" :key="index" :value="item.expressName" :label="`仅看${item.expressName}的`"></el-option>
                        </el-select>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4">
                        <el-select v-model="params.deliverStatus" placeholder="请选择商品发货状态" size="small">
                            <el-option value="" label="不限制商品发货状态"></el-option>
                            <el-option :value="0" label="仅看待发货的商品"></el-option>
                            <el-option :value="1" label="仅看已发货的商品"></el-option>
                        </el-select>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4">
                        <el-select v-model="params.sortType" placeholder="请选择排序方式" size="small">
                            <el-option :value="1" label="按收货地址汇总展示"></el-option>
                            <el-option :value="2" label="按订单汇总展示"></el-option>
                            <el-option :value="3" label="优先展示最早下单的商品"></el-option>
                            <el-option :value="4" label="优先展示最晚下单的商品"></el-option>
                        </el-select>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4">
                        <el-select v-model="params.orderStatus" placeholder="请选择订单状态" size="small">
                            <el-option value="" label="不限制订单状态"></el-option>
                            <!-- <el-option :value="1" label="仅看待处理的"></el-option> -->
                            <el-option :value="2" label="仅看待发货的"></el-option>
                            <el-option :value="3" label="仅看部分发货的"></el-option>
                            <el-option :value="4" label="仅看已发货的"></el-option>
                            <!-- <el-option :value="5" label="仅看已完成的"></el-option> -->
                            <!-- <el-option :value="6" label="仅看已过期的"></el-option> -->
                           <!-- <el-option :value="7" label="仅看已取消的"></el-option> -->
                        </el-select>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4" class="page-filter-btns">
                        <el-button type="primary" @click="search" size="small">搜索</el-button>
                        <el-button @click="reset" size="small">重置</el-button>
                    </el-col>
                </el-row>
            </div>
            <div class="page-list-box">
                <el-table
                    :data="dataList"
                    style="width: 100%"
                    v-loading="listLoading"
                    size="small"
                    header-row-class-name="page-list-table"
                    @selection-change="handleSelectionChange"
                >
                    <el-table-column type="selection"></el-table-column>
                    <el-table-column prop="deliverStatus" label="发货状态"></el-table-column>
                    <el-table-column prop="expressId" label="快递公司"></el-table-column>
                    <el-table-column prop="expressNo" label="运单号" min-width="120" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="num" label="数量"></el-table-column>
                    <el-table-column prop="goodsId" label="商品ID"></el-table-column>
                    <el-table-column prop="goodsName" label="商品名称" min-width="150" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="skuId" label="SKUID" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="skuName" label="SKU名称" min-width="150" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="expressTime" label="发货时间" min-width="150"></el-table-column>
                    <el-table-column prop="sn" label="所属订单" min-width="150"></el-table-column>
                    <el-table-column prop="orderStatusDesc" label="订单状态"></el-table-column>
                    <el-table-column prop="orderTime" label="下单时间" min-width="150"></el-table-column>
                    <el-table-column prop="customerName" label="收货人" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="telephone" label="联系电话" min-width="100"></el-table-column>
                    <el-table-column prop="address" label="收货地址" min-width="150" show-overflow-tooltip></el-table-column>
                    <el-table-column fixed="right" label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button
                                type="text"
                                size="small"
                                @click="$refs.updateDeliverInfo.show(scope.row)"
                                >{{scope.row.expressId ? '更新发货信息' : '发货'}}</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div  class="page-pagination-box clearfix MT20">
                <div class="fl">
                    <el-button type="primary" size="small" @click="batchDeliver">批量发货</el-button>
                    <el-button type="primary" size="small" @click="download" :loading="downLoading">导出</el-button>
                </div>
                <el-pagination
                    v-if="data"
                    class="fr"
                    @size-change="handleSizeChange"
                    @current-change="getDataList"
                    :current-page.sync="params.pageNo"
                    :page-sizes="[15, 30, 50]"
                    :page-size.sync="params.pageSize"
                    layout="total, sizes, prev, pager, next"
                    :total="data.totalCount || 0">
                </el-pagination>
            </div>
        </div>
        <update-deliver-info ref="updateDeliverInfo" @onUpdate="getDataList"></update-deliver-info>
    </div>
</template>
<script>
import {fetchExpress,downloadFileTmp} from '@/assets/js/commonApi';
import UpdateDeliverInfo from './components/updateDeliverInfo.vue';
const defaultParams = {
    pageNo: 1,
    pageSize: 15,
    expressId: "",
    deliverStatus: 0,
    searchType: 1,
    searchValue: "",
    sortType: 1,
    orderStatus: ''
}
export default {
    components:{UpdateDeliverInfo},
    data(){
        return{
            data: null,
            dataList: null,
            params:{
                ...defaultParams
            },
            listLoading: false,
            expressList:[],
            downLoading: false,
            goodsArry:[]
        }
    },
    created(){
        this.getExpressList();
    },
    mounted(){
        this.getDataList();
    },
    methods:{
        //获取快递公司
        getExpressList(){
            fetchExpress().then((res) => {
                const {state,result} = res.data;
                if(state == 1){
                    this.expressList = result;
                }
            }).catch(() => {})
        },
        //获取列表
        getDataList() {
            this.listLoading = true;
            this.$request({
                url: "/shopGoodDeliver/list",
                method: "POST",
                data: this.params,
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1 && result) {
                        this.data = result;
                        this.dataList = result.result;
                    } else {
                        this.$message.error(msg || "请求失败");
                    }
                })
                .catch(() => {
                    this.$message.error("请求错误");
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        //选择pageSize
        handleSizeChange(e) {
            this.params.pageNo = 1;
            this.params.pageSize = e;
            this.getDataList();
        },
        //搜索
        search() {
            this.params.pageNo = 1;
            this.getDataList();
        },
        //重置
        reset(){
            this.params = {
                ...defaultParams,
                pageSize: this.params.pageSize
            }
            this.getDataList();
        },
        //全选、反选
        handleSelectionChange(val) {
            // this.goodsIds = val.map( v => v.id );
            this.goodsArry = val;
        },
        //导出
        async download(){
            let filePath;
            this.downLoading = true;
            const res = await this.$request({
                url:'/shopGoodDeliver/Download',
                method: 'post',
                data: this.params,
            })
                .catch(err => {
                    this.$message({message: err.message, type: 'error'});
                })
            const{state,msg,result} = res.data;
            if(state == 1 && result){
                filePath = result.url;
            }else{
                this.downLoading = false;
                return this.$message.error(msg || '下载失败');
            }   

            await downloadFileTmp(filePath,'商品发货.'+this.$utils.getFileType(filePath));
            this.downLoading = false;
        },
        //批量发货
        batchDeliver(){
            if( this.goodsArry.length < 1 ){
                return this.$message.error('请勾选需要发货的商品');
            }
            let _addr = this.goodsArry.map( v => v.address);
            _addr = Array.from(new Set(_addr));
            if( _addr.length > 1 ){
                return this.$message.error('只能对收货地址相同的商品进行批量发货');
            }
            let _status = this.goodsArry.map( v => v.deliverStatus);
            _status = Array.from(new Set(_status));
            if( _status.length > 1 ){
                return this.$message.error('只能对相同发货状态的商品进行批量发货');
            }
            this.$refs.updateDeliverInfo.show({
                orderDetailIds: (this.goodsArry.map( v => v.orderDetailId)).toString()
            })
        }
    }
}
</script>
<style lang="scss" scoped>

</style>